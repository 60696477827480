import React from 'react'
import mainPhoto from "../assets/images/cely_rose_main.png"
import logo from "../assets/images/cely_rose_photography_logo_white.png"

import arrow from "../assets/images/down_arrow.resized.png"

class Header extends React.Component {
    render() {
        return (
            <section id="header">
                <nav className="fixedHeader">
                    <div className="titleHeader">
                        <ul className="navbar">
                            <li className="menulink"><a href="#portfolio" className="menuTitle">Portfolio</a></li>
                            <li className="menulink"><a href="#contact" className="menuTitle">Contact</a></li>
                        </ul>
                    </div>
                    <div className="navLogo">
                        <span className="image"><a href="#header" alt=""><img src={logo} alt="CR"/></a></span>
                    </div>

                    <div className="iconsHeader">
                        <ul className="icons">
                            <li><a href="https://www.facebook.com/Cely-Rose-Photography-108365793946789/" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
                            <li><a href="https://www.instagram.com/celyrosephotography" className="icon alt fa-instagram"><span className="label">Instagram</span></a></li>
                            <li><a href="https://twitter.com/celyrosephoto" className="icon alt fa-twitter"><span className="label">Twitter</span></a></li>
                            <li><a href="https://www.pinterest.fr/celyrosephotography" className="icon alt fa-pinterest"><span className="label">Pinterest</span></a></li>
                            <li><a href="mailto:contact@celyrosephotography.com?subject=Contact" className="icon alt fa-envelope"><span className="label">Email</span></a></li>
                        </ul>
                    </div>
                </nav>
               <div className="inner">
                    <span className="image fit"><img src={mainPhoto} alt=""/></span>
                </div>
                <div className="enter">
                    <a href="#portfolio"><img className="blink-image" src={arrow} alt=""/>Here</a>
                </div>
            </section>
        )
    }
}

export default Header